<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      userinfo: [],
      ismobile: false,
      orderpage: 1,
      orderarray: [],
      devicespage: 1,
      devicesearch: "",
      ordersearch: "",
      orderpaystatus: -1,
      orderpaytype: -1,
      ordertimes: [],
      allcount: 0,
      pickerOptions: {},
      datacount: 0,
      allpage: 0,
      activeapp: [],
      loading: true,
      empty: true,
      limit: 10,
      systemtype: 0,
      ordersettingdialog: false,
      ordersettingvalue: {},
      autorefreshdata: {
        status: false,
        task: "",
      },
      timer: 60,
      groupid: 0,
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("user.group.orders");
    that.items = [
      {
        text: that.$t("menuitems.devices.text"),
        href: "/",
      },
      {
        text: that.$t("menuitems.devices.orders"),
        href: "/device/orders",
      },
      {
        text: that.$t("user.group.orders"),
        active: true,
      },
    ];
    //   this.getdevicelist()
    that.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      // that.ismobile = true;
    }
    that.groupid = that.$route.query.id;
    that.getorderlist();
    that.getsysteminfo();
    that.websocket.ws.onmessage = function (res) {
      let wsdata = JSON.parse(res.data);
      if (wsdata.action === "readcard") {
        if (wsdata.uid) {
          that
            .$confirm(
              that.$t("device.order.checkouttip"),
              that.$t("global.delete.sureyouaction"),
              {
                confirmButtonText: that.$t("global.button.ok"),
                cancelButtonText: that.$t("global.button.cancel"),
                type: "warning",
              }
            )
            .then(() => {
              that.$axios
                .post(
                  that.apiuri,
                  {
                    action: "checkout",
                    hex: wsdata.uid,
                  },
                  {
                    headers: { Openid: that.userinfo.openid },
                  }
                )
                .then(function (response) {
                  that.getorderlist();
                  if (response.data.status == 200) {
                    that.$message({
                      message: that.$t("global.success"),
                      type: "success",
                    });
                  } else if (response.data.status == 403) {
                    that.$message({
                      message: that.$t("global.delete.auth"),
                      type: "warning",
                    });
                  } else {
                    that.$message.error(that.$t("global.delete.500"));
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch(() => {});
        }
      }
    };
  },
  computed: {
    searchinorderlist() {
      var paycode = {
        wxpay: 3,
        alipay: 2,
      };
      var datas = this.orderarray.filter((value) => {
        return (
          !this.ordersearch || value.ED_id.toLowerCase().includes(this.ordersearch.trim().toLowerCase()) ||
          !this.ordersearch || value.ED_name.toLowerCase().includes(this.ordersearch.trim().toLowerCase()) ||
          !this.ordersearch || value.user.toLowerCase().includes(this.ordersearch.trim().toLowerCase()) ||
          !this.ordersearch || value.hex.toLowerCase().includes(this.ordersearch.trim().toLowerCase())
        );
      });
      return datas;
    },
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtype = parseInt(response.data.data.model);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getorderlist: function () {
      var that = this;
      that.orderarray = [];
      that.orderlistloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getgrouporder",
            page: that.orderpage,
            limit: that.limit,
            group: that.groupid,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          var data = response.data;
          if (data.count > 0) {
            that.orderarray = data.data;
            that.datacount = data.count;
            that.allpage = data.allpage;
            that.allcount = data.total;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    logdata: function (route, id) {
      var that = this;
      if (route == "order") {
        that.$toast.loading({
          message: that.$t("global.loading.readingv2"),
          forbidClick: true,
        });
        that.$axios
          .get("https://doc.aic.pet/" + id + ".orderlog")
          .then((response) => {
            that.$toast.clear();
            window.location.href = response.data.file;
          });
      }
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getorderlist();
    },
    handleCurrentChange(val) {
      this.orderpage = val;
      this.getorderlist();
    },
    deletes(type, id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.delete.deleteordertip"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "delete",
                type: type,
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.delete.success"),
                  type: "success",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
              that.getorderlist();
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    autorefresh(status) {
      if (status) {
        this.autorefreshdata.task = setInterval(() => {
          this.getorderlist();
        }, this.timer * 1000);
      } else {
        clearInterval(this.autorefreshdata.task);
      }
    },
  },
  filters: {
    formatTimer: function (value) {
      value = value * 1000;
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" v-loading="loading">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div>
                  <label for="orderdays">
                    {{ $t("device.orders.autorefresh") }}
                  </label>
                  <div class="row">
                    <el-switch
                      v-model="autorefreshdata.status"
                      :inactive-text="$t('global.close')"
                      :active-text="$t('global.open')"
                      @change="autorefresh"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <label for="keyword">{{ $t("device.orders.keyword") }}</label>
                  <div class="row">
                    <input
                      type="text"
                      class="form-control"
                      v-model="ordersearch"
                      :placeholder="$t('device.orders.searchorder')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-body"
            style="overflow-x: auto; overflow-y: hidden"
           
          >
            <table id="deviceslist_table" class="table table-striped">
              <thead>
                <tr>
                  <th class="sortStyle">{{ $t("device.orders.orderid") }}</th>
                  <th class="sortStyle">
                    {{ $t("device.orders.goodsname") }}
                  </th>
                  <th class="sortStyle">
                    {{ $t("device.orders.salsevalues") }}ML
                  </th>
                  <th class="sortStyle">
                    {{ $t("device.orders.orderprice") }}
                  </th>
                  <th class="sortStyle" v-if="systemtype == 2">
                    {{ $t("device.orders.user") }}
                  </th>
                  <th class="sortStyle">{{ $t("device.orders.ordertime") }}</th>
                  <th class="sortStyle">{{ $t("device.orders.deviceid") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!searchinorderlist || orderarray.length == 0">
                  <td colspan="8">
                    <el-empty
                      :description="$t('device.orders.nodata')"
                    ></el-empty>
                  </td>
                </tr>
                <tr v-else-if="searchinorderlist==0">
                  <td colspan="8">
                    <el-empty
                      :description="$t('device.orders.nodata')"
                    ></el-empty>
                  </td>
                </tr>
                <tr
                  v-for="(val, idx) in searchinorderlist"
                  :class="'device' + val.id"
                  :key="val.id"
                >
                  <td>
                    <span :id="val.id">{{ val.id }}</span>
                  </td>
                  <td v-if="val.goods">{{ val.goods }}</td>
                  <td v-if="!val.goods">
                    {{ $t("device.orders.nogoodsname") }}
                  </td>

                  <td>{{ val.goodstotal }}</td>
                  <td>
                    {{ val.total }}
                  </td>
                  <td>
                    <b-tooltip :target="'user' + idx">{{ val.hex }}</b-tooltip>
                    <span :id="'user' + idx">{{ val.user }}</span>
                  </td>
                  <td v-if="val.paytime > 0">
                    {{ val.paytime | formatTimer }}
                  </td>
                  <td v-if="val.paytime == 0">
                    {{ val.addtime | formatTimer }}
                  </td>
                  <td>
                    <b-tooltip :target="'orderid' + idx">{{
                      val.ED_name
                    }}</b-tooltip>
                    <span :id="'orderid' + idx">{{ val.ED_id }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="allcount"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
</style>